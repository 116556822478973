@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes reverse-spin {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.animate-reverse-spin {
  animation: reverse-spin 1s linear infinite;
}
.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  position: fixed; /* Fixed position to stay in the center during scrolling */
  top: 0;
  left: 0;
  z-index: 9999; /* Ensure it stays on top of other content */
}

.loader-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
  height: 50%;
  background-color: #f3f3f3;
  position: relative;
  overflow: hidden;
  border-radius: 25px;
}

.loader-bar .ball {
  width: 60px;
  height: 60px;
  background-image: url('assets/ball.png'); /* Path to your ball image */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0;
  transform: scale(0);
  animation: loadBall 1s ease forwards;
}

@keyframes loadBall {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}



